import self from "../img/self.png";
import engagr from "../img/engagr.png";
import sparkeey from "../img/sparkeey.png";
import healify from "../img/healify.png";
import w3sport from "../img/w3sport.png";
import yubihealth from "../img/yubihealth.png";
import mekvahan from "../img/mekvahan.png";
import mekgarage from "../img/mekgarage.png";

export let colors = ["rgb(0,255,164)", "rgb(166,104,255)"];

export const info = {
  firstName: "Mridul",
  lastName: "Verma",
  initials: "JS",
  position: "a Software Engineer",

  // position: "Surviving on Coffee & Eminem",
  selfPortrait: self,
  gradient: `-webkit-linear-gradient(135deg, ${colors})`,
  baseColor: colors[0],
  miniBio: [
    {
      emoji: "☕",
      text: "Fueled by Coffee",
    },
    {
      emoji: "🌎",
      text: "Based in the India",
    },
    {
      emoji: "💼",
      text: "Senior Software Engineer at Recro",
    },
    {
      emoji: "📧",
      text: "mridul.verma965@gmail.com",
    },
  ],
  socials: [
    // {
    //   link: "https://facebook.com",
    //   icon: "fa fa-facebook",
    //   label: "facebook",
    // },
    // {
    //   link: "https://instagram.com",
    //   icon: "fa fa-instagram",
    //   label: "instagram",
    // },
    {
      link: "https://github.com/mkvmridul",
      icon: "fa fa-github",
      label: "github",
    },
    {
      link: "https://linkedin.com/in/mkvmridul",
      icon: "fa fa-linkedin",
      label: "linkedin",
    },
    // {
    //   link: "https://twitter.com",
    //   icon: "fa fa-twitter",
    //   label: "twitter",
    // },
  ],
  // bio: "Hello! I'm John. I'm a systems engineer for Google. I studied CompSci at Harvard, I enjoy long walks on the beach, and I believe artificial intelligence will inevitably rule us all one day. You should hire me!",

  bio: "I am a skilled backend developer with a proven track record of successfully designing and developing highly scalable backend applications from scratch. I have a deep understanding of modern technology stacks and frameworks, with a strong focus on utilizing cutting-edge tools such as Node.js and Nest.js to deliver efficient and robust solutions. My expertise lies in architecting and implementing backend systems that not only meet current requirements but also ensure future scalability and performance. I take pride in my ability to transform complex concepts into functional and maintainable code, contributing to the seamless operation of web-based applications.",
  skills: {
    proficientWith: [
      "Node.js",
      "Nest.js",
      "React.js",
      "TypeScript",
      "Javascript",
      "PostgreSQL",
      "MySQL",
      "MongoDB",
      "Redis",
      "Serverless",
      "AWS",
      "Docker",
      "Prisma",
      "Objection.js",
      // "react",
      // "git",
      // "github",
      // "bootstrap",
      // "html5",
      // "css3",
      // "figma",
    ],
    exposedTo: [
      "Elastic-search",
      "GraphQL",
      "Terraform",
      "Redux Toolkit",
      // "nodejs", "python", "adobe illustrator"
    ],
  },

  hobbies: [
    {
      label: "Playing Guitar",
      emoji: "🎸",
    },
    {
      label: "Blogging",
      emoji: "📰",
    },

    // {
    //   label: "cooking",
    //   emoji: "🌶",
    // },
  ],
  portfolio: [
    {
      title: "Sparkeey",
      live: "https://play.google.com/store/apps/details?id=com.app.sparkeey&pcampaignid=web_share",
      source: "https://github.com/mkvmridul",
      image: sparkeey,
      status: true
    },
    {
      title: "MekGarage",
      live: "https://play.google.com/store/apps/details?id=com.mani.mekvahanpartner&pcampaignid=web_share",
      source: "https://github.com/mkvmridul",
      image: mekgarage,
      status: true
    },
    {
      title: "W3Sports",
      live: "https://w3sport.co.uk/",
      source: "https://github.com/mkvmridul",
      image: w3sport,
      status: true
    },
    {
      title: "Mekvahan",
      live: "https://play.google.com/store/apps/details?id=com.myapp.mekvahan&pcampaignid=web_share",
      source: "https://github.com/mkvmridul",
      image: mekvahan,
      status: true
    },
    {
      title: "Engagr",
      live: "/project-not-live",
      source: "https://github.com/mkvmridul",
      image: engagr,
      status: false
    },
    {
      title: "YubiHealth",
      live: "/project-not-live",
      source: "https://github.com/mkvmridul",
      image: yubihealth,
      status: false
    },
    {
      title: "DRL Healify",
      live: "/project-not-live",
      source: "https://github.com/mkvmridul",
      image: healify,
      status: false
    },
  ],
};
