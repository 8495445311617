import { IconButton } from "@mui/material";
import React from "react";
import { LinkedIn as LinkedInIcon } from "@mui/icons-material";
import GitHubIcon from "@mui/icons-material/GitHub";
const Icons = [
  {
    name: "linkedin",
    icon: <LinkedInIcon fontSize="large" />,
  },
  {
    name: "github",
    icon: <GitHubIcon fontSize="large" />,
  },
];

function SocialIcon(props) {
  const {
     link,
    label,
    icon
  } = props;

  // return (
  //   <>
  //     <IconButton>{Icons?.find((row) => row.name === label)?.icon}</IconButton>
  //   </>
  // );
  return (
    <a target="_blank" aria-label={label} rel="noopener noreferrer" href={link}>
      <i className={icon} aria-hidden="true" />
    </a>
  );
}

export default SocialIcon;
